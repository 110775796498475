import React, { useState, useCallback } from 'react'

import { BreadcrumbAuto, Content, Container, Grid } from '@monorepo-amais/commons/components/Blocks'
import ConveniosCard from './ConveniosCard'
import { Search } from '@monorepo-amais/commons/components/Inputs'
import { MainTitle, SubTitle, HighLightPhone } from '@monorepo-amais/commons/components/Typography'

import EmptyResult from '@monorepo-amais/commons/components/EmptyResult'

import Fuse from 'fuse.js'
import ModalConvenios from '@monorepo-amais/commons/components/Modal/ModalConvenios'
import styled from 'styled-components'
import { insertWeightToConvenio, sortConvenios } from '../../utils/sortConvenios'

/**
 * Componente da página de convênios.
 * @param {*} {data} dados vindo da query do cosmic
 */

const Strong = styled.strong`
	margin: 0 5px 0 0;
`

export default function Convenios({ data, logo }) {
	const [show, setShow] = React.useState(true)
	const [widthContainer, setWidthContainer] = useState('')
	const [search, setSearch] = useState('')
	const [searchPlan, setSearchPlan] = useState('')
	// initialState para colocar em 'agreements' e chavear a busca
	let initialState = data.allConvenios.edges
		.map(({ node }) => {
			const { idConvenioLegado, ...details } = node.detalhe
			return {
				id: node.idLegado,
				name: node.descricao,
				...details,
				planos: node.planos,
				show: false
			}
		})
		// ordernar de acordo com a marca/regional
		.map(insertWeightToConvenio)

	initialState = initialState.sort(sortConvenios)

	const [agreements, setAgreements] = useState(initialState)

	const [plans, setPlans] = useState([])
	const [plansToSearch, setPlansToSearch] = useState([])

	// Criar os FUSEs de busca
	const fuseConvenios = new Fuse(initialState, {
		keys: ['name'],
		caseSensitive: false,
		minMatchCharLength: 1,
		threshold: 0.4
	})

	const fusePlanos = new Fuse(plans, {
		keys: ['descricao'],
		caseSensitive: false,
		minMatchCharLength: 1
	})

	const handleOpenCollapseItem = agreement => () => {
		const plansUpdated = agreements.map(item => ({
			...item,
			show: item.id === agreement.id ? !item.show : false
		}))
		setAgreements(plansUpdated)
		setPlans(agreement.planos)
		setSearchPlan('')
	}

	React.useEffect(() => {
		// setar os planos de busca
		setPlansToSearch(plans)
	}, [plans])

	React.useEffect(() => {
		// fazer a busca a cada muda no search
		// if (search !== '') handleOnSearch()
		handleOnSearch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search])

	React.useEffect(() => {
		// fazer a busca a cada muda no search
		handleOnSearchPlan()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchPlan])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleOnSearch = useCallback(() => {
		if (!(search === '')) {
			const results = fuseConvenios.search(search)
			setAgreements(results.map(node => ({ ...node, show: false })))
		} else {
			setAgreements(initialState)
		}
	})

	const handleChangeAgreementTerm = e => {
		setSearch(e.target.value)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleOnSearchPlan = useCallback(() => {
		if (!(searchPlan === '')) {
			const results = fusePlanos.search(searchPlan)
			setPlansToSearch(results)
		} else {
			setPlansToSearch(plans)
		}
	})

	const handleChangePlanTerm = e => setSearchPlan(e.target.value)
	return (
		<>
			<Container
				ref={element => {
					if (element) setWidthContainer(element.clientWidth)
				}}
			>
				{process.env.GATSBY_COSMIC_BUCKET !== 'amaispi' && (
					<ModalConvenios
						textBtn={data.cosmicjsInformacoesEstaticas.metadata.modal_botao_texto}
						text={data.cosmicjsInformacoesEstaticas.metadata.modal_texto}
						show={show}
						setShow={setShow}
						logo={logo}
					/>
				)}
				{/* <ModalConvenios
						textBtn='Entendi'
						text='Texto ainda a ser recuperado do cosmic com as informações'
						show={show}
						setShow={setShow}
					/> */}
				<BreadcrumbAuto title='Convênios' />

				<Content>
					<div className='left'>
						<MainTitle>{data.cosmicjsInformacoesEstaticas.metadata.titulo_da_pagina}</MainTitle>
						<div
							dangerouslySetInnerHTML={{
								__html: data.cosmicjsInformacoesEstaticas.metadata.subtitulo
							}}
						></div>
						<Search
							id='pesquisar-convenio'
							idButton='button-pesquisar-convenio'
							label='Digite o nome do Convênio'
							onSearch={handleOnSearch}
							onKeyPress={handleOnSearch}
							onChange={handleChangeAgreementTerm}
						/>
					</div>
					<div className='right'>
						<SubTitle>{data.cosmicjsInformacoesEstaticas.metadata.informacoes_titulo}</SubTitle>
						<div
							dangerouslySetInnerHTML={{
								__html: data.cosmicjsInformacoesEstaticas.metadata.informacoes
							}}
						></div>
						<Strong>{data.cosmicjsInformacoesEstaticas.metadata.titulo_telefone}</Strong>
						<HighLightPhone
							href={
								data.cosmicjsInformacoesEstaticas.metadata.link_whatsapp
									? data.cosmicjsInformacoesEstaticas.metadata.link_whatsapp
									: `tel:${data.cosmicjsInformacoesEstaticas.metadata.telefone}`
							}
							target={data.cosmicjsInformacoesEstaticas.metadata.link_whatsapp ? '_blank' : null}
						>
							{data.cosmicjsInformacoesEstaticas.metadata.telefone}
						</HighLightPhone>
					</div>
					<Grid className='cards' minWidthColMobile='157px' gap='0 24px'>
						{agreements.map(item => (
							<ConveniosCard
								key={`convenio-card-${item.id}`}
								widthContainer={widthContainer}
								agreements={agreements}
								active={item.show}
								onOpenCollapse={handleOpenCollapseItem(item)}
								name={item.name}
								onSearch={handleOnSearchPlan}
								onChange={handleChangePlanTerm}
								plans={plansToSearch.map(item => item.descricao)} //TODO: passar o map assim traz problemas de performance?
								{...item}
								{...data}
							/>
						))}
					</Grid>
				</Content>
			</Container>
			<EmptyResult searchType='Convênio' condition={agreements.length < 1} />
		</>
	)
}

import React from 'react'
import styled from 'styled-components'

import ShowModal from './Modal'

const Logo = styled.div`
	margin: 0 auto;
	height: 61px;
`

const Submit = styled.button`
	background: ${props => props.theme.colors.primary};
	height: 50px;
	line-height: 50px;
	border-radius: 50px;
	margin: 40px 0;
	width: 100%;
	border: none;
	color: ${props => props.theme.colors.white};
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	&:focus,
	&:hover {
		background: ${props => props.theme.colors.primary_hover};
		outline: none;
	}
`

const TextCenter = styled.div`
	text-align: center !important;
`

const SelectBox = styled.div`
	max-width: 292px;
	margin: 40px auto 0;
`

const ModalConvenios = ({ text, textBtn, show, setShow, logo }) => (
	<ShowModal show={show}>
		<Logo>{logo}</Logo>
		<TextCenter className='infoText' dangerouslySetInnerHTML={{ __html: text }} />
		<SelectBox>
			<Submit id='button-ok' onClick={() => setShow(false)}>
				{textBtn}
			</Submit>
		</SelectBox>
	</ShowModal>
)

export default ModalConvenios
